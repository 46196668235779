import React from 'react';

function Home() {
  return (
    <div>
      <h2>Welcome to the Home Page</h2>
      <p>You are authenticated and authorized to access this page.</p>
      {/* Add any additional content or functionality for the Home page */}
    </div>
  );
}

export default Home;