import { useState, useEffect } from 'react';
import Login from './Login'; // Import the Login component
import Home from './Home'; // Import the Home component
import './App.css';

function App() {
  // State to store the token and authentication status
  const [token, setToken] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if the token is available in sessionStorage
    const storedToken = sessionStorage.getItem('token');
    if (storedToken) {
      // Token available, make a POST request to the authentication endpoint
      fetch('http://api.y2do.net/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: storedToken }),
      })
        .then(response => response.json())
        .then(data => {
          // Check if the response contains an error status
          if (data.status && data.status === 'error') {
            // Show the login page if the response indicates an error
            // You need to implement your logic to show the login page here
            console.log('Error: Show login page');
            setIsAuthenticated(false); // Set authentication status to false
          } else {
            // No error, set authentication status to true
            setIsAuthenticated(true);
            console.log('Authentication successful!');
          }
        })
        .catch(error => {
          console.error('Error:', error);
          setIsAuthenticated(false); // Set authentication status to false
        });
    } else {
      // Token not available in sessionStorage, show the login page
      // You need to implement your logic to show the login page here
      console.log('Token not available: Show login page');
      setIsAuthenticated(false); // Set authentication status to false
    }
  }, []); // Empty dependency array to run this effect only once on mount

  return (
    <div className="App">
      {/* Render either the Login or Home component based on authentication status */}
      {isAuthenticated ? <Home /> : <Login />}
    </div>
  );
}

export default App;
