import { useState } from 'react';

function Login() {
  // State variables to store the username and password
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();

    // Create payload containing username and password
    const payload = { uname: username, upass: password };

    try {
      // Send POST request to the login endpoint using fetch
      const response = await fetch('http://api.y2do.net/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      // Parse response JSON
      const data = await response.json();

      // Check if the response is successful
      if (response.ok) {
        // Get the token from the response data
        const { token } = data;

        // Save the token into sessionStorage
        sessionStorage.setItem('token', token);

        // Redirect or handle successful login
        console.log('Login successful!');
      } else {
        // Handle login error
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="username">Username:</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;
